<template>
  <div class="onboarding-form tw-bg-white tw-rounded-lg tw-shadow-xl tw-px-5 tw-py-8">
    <h2
      class="onboarding-form__title tw-text-xl tw-font-normal tw-flex tw-items-center tw-relative tw-mb-12"
      data-test="title"
    >
      <span
        v-text="$t('onboarding.titles.documents')"
        data-test="title-content"
      />
      <ui-material-icon
        v-b-tooltip.right.hover="tooltipOptions"
        class="tw-text-base tw-ml-1 tw-mr-1 tw--mb-px"
        name="info"
        data-test="icon"
        @click.native="click"
      />
    </h2>

    <ValidationObserver
      ref="observer"
    >
      <form
        :disabled="$wait.is('uploading onboarding documents')"
        data-test="form"
        @submit.prevent="submitted"
      >
        <ValidationProvider
          v-for="document in documents"
          :key="document.key"
          :name="document.label"
          class="tw-flex tw-flex-col"
          :rules="`size:${MAX_FILE_SIZE}|mimes:${ACCEPTED_MIME_TYPES}${document.required ? '|required' : ''}`"
          tag="div"
        >
          <template slot-scope="{ validate, errors, invalid }">
            <onboarding-document-input
              v-model="formData.files[document.key]"
              :id="document.key"
              :label="document.label"
              :hint="document.hint"
              :required="document.required"
              :error="invalid ? errors[0] : null"
              :data-test="`field-${document.key}`"
              :disabled="$wait.is('uploading onboarding documents')"
              class="tw-mb-8"
              @input="validate"
            />
          </template>
        </ValidationProvider>

        <div
          class="tw-flex tw-justify-center tw-items-center"
        >
          <ui-button
            :loading="$wait.is('uploading onboarding documents')"
            :disabled="$wait.is('uploading onboarding documents')"
            type="submit"
            class="tw-rounded-full tw-text-white tw-px-12"
            variant="primary"
            data-test="submit"
          >
            {{ $t('onboarding.buttons.upload_documents') }}
          </ui-button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import { defineComponent } from '@vue/composition-api'

  import OnboardingDocumentInput from './_subs/OnboardingDocumentInput/index.vue'

  import { showToaster } from '@/services/Toaster'
  import { Onboarding } from '@/resources'
  import { i18n } from '@/locales'
  import useTooltipDialog from '@/composables/useTooltipDialog'
  import useSize from '@/composables/useSize'

  export const ACCEPTED_MIME_TYPES = ['image/jpeg', 'image/png', 'image/gif', 'image/tiff', 'application/pdf']
  const MAX_FILE_SIZE = 2048

  /**
   * @module component - OnboardingForm
   * @param {Array<any>} documents - A list of documents
   */
  export default defineComponent({
    name: 'OnboardingForm',
    props: {
      documents: {
        type: Array,
        required: true
      }
    },
    components: {
      OnboardingDocumentInput
    },
    setup () {
      const allowedDocumentFormatsKeys = {
        max_file_size: useSize(MAX_FILE_SIZE * 1024),
        image_formats: ACCEPTED_MIME_TYPES
          .map(type => type.split('/')[1])
          .filter(type => type !== 'pdf')
          .join(',')
      }

      const { click, haveDialog } = useTooltipDialog(i18n.t('onboarding.titles.documents'), i18n.t('onboarding.paragraphs.allowed_document_formats', allowedDocumentFormatsKeys))

      return {
        click,
        haveDialog,
        allowedDocumentFormatsKeys
      }
    },
    data () {
      return {
        formData: {
          files: {}
        },
        MAX_FILE_SIZE,
        ACCEPTED_MIME_TYPES: ACCEPTED_MIME_TYPES.join(',')
      }
    },
    computed: {
      ...mapGetters('auth', [
        'getCid'
      ]),
      /**
       * @function tooltipOptions
       * @returns {object}
       */
      tooltipOptions () {
        return {
          title: this.$t('onboarding.paragraphs.allowed_document_formats', this.allowedDocumentFormatsKeys),
          boundary: 'window',
          disabled: this.haveDialog
        }
      }
    },
    methods: {
      ...mapActions('onboarding', [
        'retrieveDocuments'
      ]),
      submitted () {
        // @ts-ignore
        this.$refs.observer.validate()
          .then((/** @type {boolean} */ valid) => {
            if (!valid) return

            if (this.$wait.is('uploading onboarding documents')) return

            const formData = new FormData()
            Object.keys(this.formData.files).forEach(key => {
              // @ts-ignore
              formData.append(key, this.formData.files[key])
            })

            this.$wait.start('uploading onboarding documents')
            Onboarding.documents({
              cid: this.getCid
            }, formData)
              .then(async () => {
                await this.retrieveDocuments()

                this.$router.push({
                  name: 'OnboardingConfirmation'
                }).catch(() => {})
              })
              .catch(err => {
                if (!err.response) return

                const { data } = err.response
                let message = this.$t('an_error_has_occurred')
                if (data && data.error && data.error.title) message = data.error.title

                showToaster(this, message, {
                  type: 'error',
                  position: 'bottom-right'
                })
              })
              .finally(() => {
                this.$wait.end('uploading onboarding documents')
              })
          })
      }
    }
  })
</script>

<style lang="scss" scoped>

  .onboarding-form {
    &__title {
      &::after {
        position: absolute;
        content: '';
        height: 1px;
        left: 0;
        bottom: -6px;
        width: 33%;
        background-color: $divider;
      }
    }
  }

</style>
