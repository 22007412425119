import { EventBus } from '@/services/EventBus'
import { computed } from '@vue/composition-api'

export default function useTooltipDialog (title, content) {
  const haveDialog = computed(() => {
    const { clientWidth } = document.documentElement
    return clientWidth <= 455
  })

  function click () {
    if (!haveDialog.value) return

    EventBus.$emit('dialogs:tooltip', {
      title,
      content
    })
  }

  return {
    click,
    haveDialog
  }
}
