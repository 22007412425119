<template>
  <div class="new-shipment-address-available-countries-list tw-rounded tw-px-5 tw-py-3 tw-z-10">
    <div
      class="new-shipment-address-available-countries-list__header tw-pb-1 tw-mb-4 tw-font-medium"
      v-text="title"
    />
    <ul
      data-test="list"
    >
      <li
        v-for="country in countries"
        :key="country.code"
        :class="{
          disabled: country.disabled
        }"
        class="tw-flex tw-items-center new-shipment-address-available-countries-list__item"
        data-test="item"
        @click="select(country)"
      >
        <ui-flag
          :country="country.code"
          data-test="flag"
        />
        <div
          data-test="name"
          class="new-shipment-address-available-countries-list__item__name tw-flex tw-items-center"
        >
          <span
            v-text="country.name"
          />
          <ui-material-icon
            v-if="country.isImportExport"
            v-b-tooltip.right.hover="tooltipOptions"
            class="tw-text-base tw-ml-1 tw-mr-1 tw--mb-px"
            name="info"
            data-test="icon"
            @click.native="click"
          />
          <UiBadge
            v-if="country.isBeta"
            color="green"
            size="sm"
            class="new-shipment-address-available-countries-list__item__name__beta"
            data-test="beta"
          >
            {{ $t('app.labels.beta') }}
          </UiBadge>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import { i18n } from '@/locales'
  // @ts-ignore
  import { availableCountries } from '@/../config/chronoinfos'
  import useTooltipDialog from '@/composables/useTooltipDialog'

  /**
   * @module component - NewShipmentAddressAvailableCountriesList
   * @param {string} title - The title of the section
   * @param {Array<string>} [disabledCountries=[]] - A list of ISO-2 country codes to disable
   */
  export default defineComponent({
    name: 'NewShipmentAddressAvailableCountriesList',
    props: {
      title: {
        type: String,
        required: true
      },
      disabledCountries: {
        type: Array,
        default: () => []
      }
    },
    setup () {
      const { click, haveDialog } = useTooltipDialog(null, i18n.t('new-shipment.paragraphs.add_address.import_export'))

      return {
        click,
        haveDialog
      }
    },
    computed: {
      /**
       * @function tooltipOptions
       * @return {object}
       */
      tooltipOptions () {
        return {
          title: this.$t('new-shipment.paragraphs.add_address.import_export'),
          boundary: 'window',
          disabled: this.haveDialog
        }
      },
      /**
       * @function countries
       * @return {Array<any>}
       */
      countries () {
        const betaCountries = ['PL']

        return availableCountries
          .map((/** @type {{ 'iso-2': string, 'disabled-for-home': boolean }} */ country) => ({
            code: country['iso-2'],
            name: this.$t(country['iso-2']),
            isBeta: betaCountries.includes(country['iso-2']),
            isImportExport: !!country['disabled-for-home'],
            disabled: this.disabledCountries.includes(country['iso-2'])
          }))
      }
    },
    methods: {
      /**
       * @function select
       * @param {{ code: string, disabled: boolean }} country
       */
      select (country) {
        if (country.disabled) return

        this.$emit('select', country.code)
      }
    }
  })
</script>

<style lang="scss" scoped>

  .new-shipment-address-available-countries-list {
    background-color: $light-gray;

    ul,
    li {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }

    &__header {
      border-bottom: 1px solid white;
    }

    &__item {
      cursor: pointer;
      margin-bottom: 0.4em !important;

      &__name {
        line-height: 0.8;
      }

      &.disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }

</style>
