<template>
  <div
    :class="{
      'has-error': error,
      'is-disabled': $attrs.disabled
    }"
    class="onboarding-document-input tw-flex tw-flex-col"
  >
    <div class="onboarding-document-input__header tw-flex tw-justify-between">
      <label
        :for="id"
        data-test="label"
      >
        {{ label }}
      </label>
      <span
        v-if="!$attrs.required"
        data-test="optional"
        class="tw-italic tw-mr-1"
      >
        {{ $t('optional') | capitalize }}
      </span>
    </div>

    <button
      :title="label"
      type="button"
      class="onboarding-document-input__container tw-appearance-none tw-rounded tw-flex tw-py-2 tw-px-3 tw-items-center tw-cursor-pointer"
      data-test="drop-zone"
      @dragover.stop.prevent="handleDragOver"
      @drop.stop.prevent="handleDrop"
      @click="triggerInput"
    >
      <div class="tw-flex tw-items-center tw-w-full">
        <span
          v-if="!file"
          class="tw-text-blue-500"
          data-test="add-document"
          v-text="$t('onboarding.buttons.add_document')"
        />
        <div
          v-else
          class="tw-flex tw-justify-between tw-w-full"
          data-test="preview-document"
        >
          <span
            class="tw-flex-1 tw-text-gray-600 tw-truncate tw-text-left"
            data-test="preview-document-name"
            v-text="file.name"
          />
          <span
            v-if="!$attrs.disabled"
            class="tw-text-blue-500"
            data-test="preview-document-edit"
          >
            {{ $t('edit') | capitalize }}
          </span>
        </div>
      </div>
    </button>

    <input
      ref="input"
      type="file"
      class="tw-hidden"
      data-test="file-input"
      :accept="ACCEPTED_MIME_TYPES"
      :name="id"
      :id="id"
      :disabled="$attrs.disabled"
      @change="handleFileChange"
    >

    <div
      v-if="error"
      class="onboarding-document-input__error tw-text-xs tw-text-red-500"
      data-test="error"
      v-text="error"
    />
  </div>
</template>

<script>
  import { ACCEPTED_MIME_TYPES } from '@/views/Carriers/Onboarding/components/OnboardingForm'

  /**
   * @module component - OnboardingDocumentInput
   * @param {string} id
   * @param {string} label
   * @param {string} [hint=null]
   * @param {string} [error=null]
   */
  export default {
    name: 'OnboardingDocumentInput',
    props: {
      value: {
        type: null,
        default: null
      },
      id: {
        type: String,
        required: true
      },
      label: {
        type: String,
        required: true
      },
      hint: {
        type: String,
        default: null
      },
      error: {
        type: String,
        default: null
      }
    },
    data () {
      return {
        file: null,
        ACCEPTED_MIME_TYPES: ACCEPTED_MIME_TYPES.join(',')
      }
    },
    methods: {
      triggerInput () {
        this.$refs.input.click()
      },
      handleFileChange (e) {
        if (this.$attrs.disabled) return false

        const { files } = e.target

        if (files.length > 0) {
          this.file = files[0]
          this.$emit('input', this.file)
        }
      },
      handleDragOver (e) {
        e.dataTransfer.dropEffect = 'copy'
      },
      handleDrop (e) {
        if (this.$attrs.disabled) return false

        const { files } = e.dataTransfer
        if (files.length > 0) {
          this.file = files[0]
          this.$emit('input', this.file)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>

  .onboarding-document-input {
    &__container {
      border: 1px dashed $divider;
      transition: border-color 200ms, background-color 200ms;
      background-color: transparent;

      &:focus {
        outline: none;
      }

      &:hover,
      &:focus {
        border-color: $info;
      }
    }

    &.has-error {
      .onboarding-document-input__container {
        border: 1px solid $danger;
      }
    }

    &.is-disabled {
      .onboarding-document-input__container {
        cursor: not-allowed;
        background-color: rgba(black, 0.05);
        filter: grayscale(100%);

        &,
        &:hover,
        &:focus {
          border-color: darken($divider, 5%);
        }
      }
    }
  }

</style>
