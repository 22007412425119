<template>
  <div class="onboarding-confirmation">
    <div class="tw-container tw-mx-auto tw-flex md:tw-pt-24 tw-z-10">
      <div class="onboarding-confirmation__content tw-text-white md:tw-w-1/2">
        <div class="onboarding-confirmation__content__header">
          <span
            class="tw-text-base tw-uppercase tw-text-gray-500"
            data-test="subtitle"
            v-text="$t('onboarding.titles.confirmation_sub')"
          />
          <h1
            data-test="title"
            class="onboarding-confirmation__content__header__title tw-mb-4 tw-text-4xl tw-font-light"
            v-text="$t('onboarding.titles.confirmation')"
          />
        </div>
        <p
          v-text="$t('onboarding.paragraphs.confirmation')"
          class="md:tw-w-2/3 tw-mb-12"
          data-test="confirmation-paragraph"
        />
        <new-shipment-address-available-countries-list
          :title="$t('onboarding.titles.confirmation.countries_available')"
          data-test="countries"
        />
      </div>
    </div>
    <img
      src="@/assets/img/illustrations/countries-available__negative.svg"
      class="onboarding-confirmation__map tw-absolute tw-select-none tw-pointer-events-none tw-z-0 tw-right-0 tw-bottom-0"
      alt=""
      data-test="map"
    >
  </div>
</template>

<script>
  import store from '@/store'

  import NewShipmentAddressAvailableCountriesList from '@/views/Shippers/NewShipment/_subs/NewShipmentAddress/components/NewShipmentAddressAdd/_subs/NewShipmentAddressAvailableCountries/_subs/NewShipmentAddressAvailableCountriesList'

  /**
   * @module view - OnboardingConfirmation
   */
  export default {
    name: 'OnboardingConfirmation',
    components: {
      NewShipmentAddressAvailableCountriesList
    },
    metaInfo () {
      return {
        title: this.$t('onboarding.titles.confirmation')
      }
    },
    async beforeRouteEnter (to, from, next) {
      if (!store.getters['auth/isPreRegistered']) {
        return next({
          name: 'Auth'
        })
      }

      /**
       * Check if we already have a documents list from the previous route.
       * Otherwise check it and redirect.
       */
      let documents = store.getters['onboarding/getDocuments']
      if (documents.length === 0) {
        const res = await store.dispatch('onboarding/retrieveDocuments')
        if (res.status === 200 && res.data.documents) {
          documents = res.data.documents
        }
      }

      const hasUploadedEveryRequiredDocument = documents
        .filter(document => document.required)
        .every(document => document.uploaded)

      if (!hasUploadedEveryRequiredDocument) {
        return next({
          name: 'Onboarding'
        })
      }

      store.dispatch('setAppReady', true)
      next()
    }
  }
</script>

<style lang="scss" scoped>

  .onboarding-confirmation {
    padding: 32px 0 64px 0;
    overflow-y: scroll !important;
    background-color: $secondary-lighten;

    &__map {
      min-height: 100%;
      max-height: 100%;
      max-height: 100vh;
      max-height: calc(100vh - 55px);
      margin-left: auto;
      user-select: none;
    }

    .new-shipment-address-available-countries-list {
      background-color: #21222E;
      max-width: 240px;
    }

    @media only screen and (max-width: $breakpoint-tablet) {
      &__map {
        min-height: auto;
        max-height: 400px;
      }
    }
  }

</style>

<style lang="scss">

  .onboarding-confirmation .new-shipment-address-available-countries-list .new-shipment-address-available-countries-list__header {
    border-bottom: none;
  }

  .onboarding-confirmation .new-shipment-address-available-countries-list .new-shipment-address-available-countries-list__item__name .material-icons {
    display: none;
  }

  .onboarding-confirmation .new-shipment-address-available-countries-list .new-shipment-address-available-countries-list__item__name .new-shipment-address-available-countries-list__item__name__beta {
    margin-left: 0.25rem;
  }

</style>
